import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "./summit.css"
import "../../styles/casestudy.css"
import "../../styles/styles.css"

const Summit = () => {
    return (
        <div>
            <Navbar />
            <div className="caseStudyDetail">
                <div className="caseStudyDetailContainer">
                    <div className="caseStudyDetailBackground bg-black">
                        <div className="caseStudyDetailCoverContainer">
                            <div className="caseStudyDetailTitleContainer">
                                <div className="caseStudyDetailTitle txt-white">
                                    <h3>Singapore Urban Multi-Modal Integrated Transport Simulation (SUMMIT)</h3>
                                </div>
                            </div>
                            <div className="caseStudyDetailCoverImage">
                                <img src="/assets/case-study-covers/summit-detail-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailIntroContainer">
                            <div className="projectIntro">
                                <h4>About SUMMIT</h4>
                                <p>Developed by Agency for Science, Technology and Research (A*STAR), SUMMIT is a visualization platform that integrates and manipulates diverse data streams from buses, taxis, and trains.</p>
                                <p>SUMMIT allows users to create visualizations using different modes, such as heatmaps, 3D and 2D bar charts, and moving particles, offering unique perspectives on Singapore's transportation system.</p>
                                <div className="caseStudyDetailClientLogo">
                                    <div className="clientLogoItem"><img src="/assets/company-logos/astar-logo.png" alt="" /></div>
                                    <div className="clientLogoItem"><img src="/assets/company-logos/lta-logo.png" alt="" /></div>
                                </div>
                            </div>
                            <div className="introDivider">

                            </div>
                            <div className="projectOverview">
                                <h4>Overview</h4>
                                <div className="projectOverviewDetail">
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Industry</p>
                                        </div>
                                        <div className="textData">
                                            <p>Public Transportation</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Timeline</p>
                                        </div>
                                        <div className="textData">
                                            <p>May 2020 - Mar 2021</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>My Role</p>
                                        </div>
                                        <div className="textData">
                                            <p>User Experience Design</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Background</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Urban transportation has evolved significantly, moving away from physical tickets and passes to advanced digital systems. In a bustling metropolis like Singapore, residents use the EZ-link card, a rechargeable contactless smart card, to access public transport. Additionally, GPS systems in buses and taxis generate vast amounts of data. This digital data offers new opportunities to understand and optimize transportation systems under various conditions. </p>
                                    <p>However, the challenge lies in uniting policymakers, system operators, and researchers to collaboratively address transport issues.</p>
                                    <img className='rounded-img' src="assets/case-study-images/summit/summit-img1.jpg" alt="" />
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Need for Effective Communication</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Clare Tan, a software engineer at a research institute in Singapore and an engineering graduate from Imperial College, London, is part of a team dedicated to building robust and scalable urban transportation systems using data-driven models and simulations.</p>
                                    <p>Clare emphasizes the importance of communicating research findings to stakeholders, including transport operators, government officials, and the public, to drive meaningful change.</p>
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Visualizing Data for Impact</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Translating complex data into actionable insights is challenging. Clare notes, “It’s one thing to read a scientific paper to find out that running your buses at an increased frequency reduces congestion by half. But it’s much better to press a button and see changes happen on the screen, or be able to compare two scenarios side by side!”.</p>
                                    <p>Effective visualization and storytelling are essential to involve all stakeholders, each with different priorities, in understanding the interconnectedness of various transportation modes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Developing the SUMMIT Platform</h4>
                                <div
                                    className="caseStudyDetailInnerContentWrapper">
                                    <p>SUMMIT's web-based dashboard enables users to toggle different transport modes via the left-hand panel, select specific services or lines, and view data in intuitive ways. </p>
                                    <img src="assets/case-study-images/summit/summit-img2.png" alt="" />
                                    <p>For example, individual train trips can be visualized as moving particles, while train station passenger loads appear as 3D bar charts on a map. Users can also drill down into granular details by clicking on mini 2D bar charts to see how passenger loads vary over time at specific bus stops or along bus routes.</p>
                                    <img src="assets/case-study-images/summit/summit-img3.png" alt="" />
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Power of Playback</h4>
                                <div
                                    className="caseStudyDetailInnerContentWrapper">
                                    <p>One of SUMMIT's most popular features is its playback interface, which runs simulations minute by minute. This allows stakeholders to visualize the effects of disruptions, such as track faults or accidents, on passenger loads and trip delays.</p>
                                    <img src="assets/case-study-images/summit/summit-img4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Impact and Future Prospects</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>SUMMIT has significantly improved communication between researchers and public officials, helping to close the feedback loop essential for implementing effective policies. Clare's group can now visualize multiple scenarios, such as increased population density, to show stakeholders potential future impacts on Singapore's transportation system.</p>
                                    <p>Looking ahead, Clare's team plans to make SUMMIT widely accessible and integrate it into policy toolboxes for planners and analysts. They also aim to extend the platform to Tableau dashboards, enabling users to access and interact with the data on their tablets.</p>
                                    <p>SUMMIT exemplifies how data visualization can transform urban transportation planning, making complex data comprehensible and actionable for all stakeholders involved.</p>
                                    <img className='rounded-img' src="assets/case-study-images/summit/summit-img5.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Summit