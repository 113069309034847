import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "./faster.css"
import "../../styles/casestudy.css"
import "../../styles/styles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheck} from '@fortawesome/free-solid-svg-icons'

const Faster = () => {
    return (
        <div>
            <Navbar />
            <div className="caseStudyDetail">
                <div className="caseStudyDetailContainer">
                    <div className="caseStudyDetailBackground bg-black">
                        <div className="caseStudyDetailCoverContainer">
                            <div className="caseStudyDetailTitleContainer">
                                <div className="caseStudyDetailTitle txt-white">
                                    <h3>Fusion Analytics for public Transport Emergency Response (FASTER)</h3>
                                </div>
                            </div>
                            <div className="caseStudyDetailCoverImage">
                                <img src="/assets/case-study-covers/faster-detail-cover.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailIntroContainer">
                            <div className="projectIntro">
                                <h4>About FASTER</h4>
                                <p>IBM Research partners with governments worldwide to develop data-driven solutions for smarter cities, addressing everyday challenges. </p>
                                <p>In Singapore, IBM Research collaborated with the Land Transportation Authority (LTA) to develop the Fusion Analytics for Public Transport Emergency Response (FASTER) system, a sophisticated platform designed to streamline urban transportation and improve commuter experiences.</p>
                                <div className="caseStudyDetailClientLogo">
                                    <div className="clientLogoItem"><img src="/assets/company-logos/ibm-logo.png" alt="" /></div>
                                    <div className="clientLogoItem"><img src="/assets/company-logos/lta-logo.png" alt="" /></div>
                                </div>
                            </div>
                            <div className="introDivider">

                            </div>
                            <div className="projectOverview">
                                <h4>Overview</h4>
                                <div className="projectOverviewDetail">
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Industry</p>
                                        </div>
                                        <div className="textData">
                                            <p>Public Transportation</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Platform</p>
                                        </div>
                                        <div className="textData">
                                            <p>Web</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>Timeline</p>
                                        </div>
                                        <div className="textData">
                                            <p>August 2016 - June 2018</p>
                                        </div>
                                    </div>
                                    <div className="textContainer">
                                        <div className="textTitle">
                                            <p>My Role</p>
                                        </div>
                                        <div className="textData">
                                            <p>UX Design, UI Design, Interactive Design</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Challenge</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>The Singapore’s public transportation network serves over 6.3 million commuters daily. Given this immense volume, even minor disruptions can cause significant delays, affecting hundreds of thousands of passengers and leading to widespread inconvenience.</p>
                                    <p>The challenge was to create a system that could manage commuter flows efficiently, promptly detect incidents, and respond effectively to minimize disruptions.</p>
                                    <img className='rounded-img' src="assets/case-study-images/faster/faster-img1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-white">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>The Solution</h4>
                                <div
                                    className="caseStudyDetailInnerContentWrapper">
                                    <p>Joining the project as User Experience Designer, I worked closely with IBM Researcher, ST Engineering Developers and the LTA administrator to create an intuitive and robust interface for the FASTER system.</p>
                                    <p>Our goal was to design a user-friendly solution that centralizes real-time transportation data, enhancing the ability to monitor and manage the public transport network effectively.</p>
                                    <img className='rounded-img' src="assets/case-study-images/faster/faster-img2.jpg" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div
                                    className="caseStudyDetailInnerContentWrapper">
                                    <h5>1. Real-Time Monitoring</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>FASTER collects data from various sources, including travel cards, cellular networks, Wi-Fi networks, and CCTV cameras.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>The system provides transportation staff with real-time insights into the status of bus, train, and taxi fleets, allowing for immediate detection of issues and swift responses.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-monitor.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>2. Predictive Analytics</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>The platform leverages advanced analytics to predict potential bottlenecks and disruptions within the transportation network.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>By analyzing patterns and trends, FASTER enables proactive measures to prevent issues before they escalate.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-response-plan.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>3. Performance Indicators</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Users interact with the FASTER system through key performance indicators (KPIs) displayed at the command center.</p>
                                                </div>
                                            </div>
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>KPIs include real-time metrics such as estimated platform crowd sizes, delays in dwell times, and prolonged passenger wait times.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>These metrics are updated continuously as new data becomes available, ensuring that users have the most current information despite potential data source latency. This common representation model is essential for all analysis and decision-making.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-kpi.png" alt="" />
                                </div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>4. Actionable Insights</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>FASTER provides actionable insights into commuter behavior, helping the LTA optimize routes and schedules to better serve the public.</p>
                                                </div>
                                            </div>
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Users can replay past days to analyze historical data and simulate events with adjustments based on estimated values from those days.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Data-driven decisions lead to a more efficient and reliable transportation system.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-playback.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>5. Intuitive User Interface</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Features include intuitive dashboards, clear visualizations of data, and streamlined workflows to facilitate quick decision-making.</p>
                                                </div>
                                            </div>
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Customizable alerts and notifications help staff stay informed of any incidents in real time.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>The interface was designed with the end-user in mind, ensuring ease of use for transportation staff.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-sms.png" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>6. Testing in Real Work Environment</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>To ensure the FASTER system met the practical needs of LTA administrators, we conducted extensive testing in real work environments.</p>
                                                </div>
                                            </div>
                                            <div className="stepItem stepItem2 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Observing administrators as they used the system in their daily tasks provided valuable insights into their workflow and the challenges they faced.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Feedback from these sessions highlighted the need for a user interface that could be comfortably used in low-light conditions, where many administrators worked during night shifts or in dimly lit control rooms.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img className='rounded-img' src="assets/case-study-images/faster/faster-img4.jpg" alt="" />
                                </div>
                                <div className="contentDivider"></div>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <h5>7. Dark Mode Theme</h5>
                                    <div className="caseStudyStepContainer">
                                        <h6>Action</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem1 bg-white border-lightgrey">
                                                <div className="stepNumber bg-lightblue txt-blue">
                                                    <span className='icon'><FontAwesomeIcon icon={faCheck} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Recognizing that LTA administrators often work in low-light environments, we developed a dark mode theme for the FASTER interface.</p>
                                                </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                                    <div className="caseStudyStepContainer">
                                        <h6>Outcome</h6>
                                        <div className="stepContainer">
                                            <div className="stepItem stepItem2 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>This theme reduces eye strain and glare, providing a comfortable viewing experience during long hours of monitoring.</p>
                                                </div>
                                            </div>
                                            <div className="stepItem stepItem2 bg-white border-blue">
                                                <div className="stepNumber bg-blue txt-white">
                                                    <span className='icon'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                </div>
                                                <div className="stepContent">
                                                    <p>Dark mode also enhances the visibility of critical alerts and data visualizations, ensuring that important information stands out even in dim lighting conditions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="assets/case-study-images/faster/faster-darkmode.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="caseStudyDetailBackground bg-lightgrey">
                        <div className="caseStudyDetailContentContainer">
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Impact and Outcomes</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>The implementation of the FASTER system has significantly improved the reliability and safety of Singapore's public transportation network. Every day, the platform ensures smoother commutes for millions of passengers by enabling the LTA to monitor and manage the transportation system more effectively. </p>
                                    <p>The intuitive design of the user interface, including the dark mode theme, has empowered transportation staff to respond swiftly to incidents, minimizing disruptions and enhancing overall commuter satisfaction.</p>
                                </div>
                            </div>
                            <div className="caseStudyDetailContentWrapper">
                                <h4>Conclusion</h4>
                                <div className="caseStudyDetailInnerContentWrapper">
                                    <p>Through the collaboration between IBM Research, the LTA, and our design team, the FASTER system stands as a testament to the power of data-driven solutions in transforming urban mobility. By prioritizing user experience and leveraging advanced analytics, we have contributed to a smarter, safer, and more efficient transportation network in Singapore.</p>
                                    <img className='rounded-img' src="assets/case-study-images/faster/faster-img3.jpg" alt="" />
                                    <p>Read more about FASTER <a className='external-link' href="https://www.ifaamas.org/Proceedings/aamas2019/pdfs/p1404.pdf" target='_blank' rel="noreferrer">here</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Faster